.sign-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}
.sign-input__label {
  width: 100%;
  margin: 0;
  margin-top: 8px;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #a0a0a0;
}

.sign-input__input {
  width: 100%;
  margin: 0;
  margin-top: 5px;
  padding: 0;
  padding-bottom: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  outline: none;
  border: none;
  border-bottom: 1px solid #e8e8e8;
}

.sign-input__input:focus {
  border-bottom: 1px solid #2be080;
}

.sign-input__input:invalid {
  color: #ee3465;
}
.sign-input__error {
  display: inline-block;
  min-height: 12px;
  width: 100%;
  margin: 0;
  margin-top: 6px;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #ee3465;
}


@media (max-width: 520px) {
  .sign-input__label {
    margin-top: 7px;

  }

  .sign-input__error {
    margin-top: 7px;
  }
}