.movies-card-list {
  width: 89.0625%;
  margin: 0 auto;
  padding-top: 71px;
  box-sizing: border-box;
}

.movies-card-list__items {
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 30px 24px;
  justify-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

@media (max-width: 830px) {
  .movies-card-list {
    width: 92.1875%;
  }

  .movies-card-list__items {
    grid-template-columns: repeat(2, auto);
    gap: 36px 30px;
  }
}

@media (max-width: 530px) {
  .movies-card-list {
    width: 93.75%;
    padding-top: 41px;
  }

  .movies-card-list__items {
    grid-template-columns: repeat(1, auto);
    gap: 16px
  }
}
