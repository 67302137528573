.content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
}

.content_opened::after {
    content: "";
    position: fixed;
    inset: 0;
    background-color: #0000004d;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    }