.register__form {
  width: 396px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  margin-top: 34px;
  padding: 0;
}

@media (max-width: 520px) {
  .register__form {
    width: 81.25%;
    margin-top: 75px;
  }
}
