.filter-checkbox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 14px;
}

.filter-checkbox__title {
  margin: 0;
  margin-top: 96px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
}

.filter-checkbox__button {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  padding-right: 2px;
  padding-left: 2px;
  margin-top: 94px;
  width: 36px;
  height: 20px;
  box-sizing: border-box;
  background-color: #2be080;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  transition: color, left 0.3s ease-in-out;
}

.filter-checkbox__icon {
  position: relative;
  left: 16px;
  margin: 0;
  padding: 0;
  width: 16px;
  height: 16px;
  border: none;
  border-radius: 16px;
  background-color: #ffffff;
  background-position: center;
  cursor: pointer;
  transition: color, left 0.3s ease-in-out;
}

.filter-checkbox__button:hover {
  opacity: 0.8;
}

.filter-checkbox__button_disable {
  background-color: #ebebeb;
  transition: color, left 0.3s ease-in-out;
}

.filter-checkbox__icon_disable {
  left: 0;
  background-color: #f5f5f5;
  transition: color, left 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .filter-checkbox {
    gap: 9px;
  }

  .filter-checkbox__title {
    margin-top: 3px;
  }

  .filter-checkbox__button {
    margin-top: 0;
  }
}

@media (max-width: 320px) {
  .filter-checkbox__title {
    font-size: 11px;
  }
}
