.pop-up {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    min-height: 100%;
    background-color: #00000080;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0.4s, opacity 0.4s linear;
}

.pop-up_opened {
    visibility: visible;
    opacity: 1;
}

.pop-up__container {
    position: fixed;
    width: 100%;
    max-width: 430px;
    display: flex;
    flex-direction: column;
    padding: 34px 36px 37px 36px;
    background-color: #ffffff;
    box-sizing: border-box;
    border-radius: 10px;
    z-index: 1;
    box-shadow: 0px 0px 25px 0px #00000026;
}

.pop-up__close-icon {
    position: absolute;
    padding: 0;
    top: -40px;
    right: -40px;
    width: 32px;
    height: 32px;
    background-color: #00000000;
    border: none;
    background-image: url(../../images/pop-up__close-icon.svg);
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
}

.pop-up__close-icon:hover {
    opacity: 0.6;
}

.pop-up__status-icon {
    margin: 0 auto;
    margin-top: 30px;
    width: 120px;
    height: 120px;
}

.pop-up__title {
    margin: 0;
    margin-top: 30px;
    color: #000000;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    text-align: center;

    margin-bottom: 25px;
}

@media (max-width: 530px) {
    .pop-up__container {
        width: 88.125%;
        max-width: 400px;
        padding: 25px 22px 25px 22px;
    }

    .pop-up__close-icon {
        width: 20px;
        height: 20px;
        right: 0px;
        top: -36px;
    }

    .pop-up__status-icon {
        margin-top: 25px;
    } 

    .pop-up__title {
        margin-top: 40px;
        margin-bottom: 75px;
        font-size: 20px;
        line-height: 24px; 
    }
    
}