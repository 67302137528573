.logo {
    min-width: 38px;
    height: 38px;
    background-image: url(../../images/header__logo.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }

  
.logo:hover {
    opacity: 0.8;
  }