.movies-card {
  max-width: 364px;
  height: 271px;
  margin: 0;
  padding: 0;
}

.movies-card__info {
  margin: 0;
  padding: 0;
  padding: 14px 14px 13px 14px;
  display: grid;
  grid-template-columns: minmax(162px, 296px) auto;
  gap: 10px 10px;
  justify-content: space-between;
}

.movies-card__title {
  margin: 0;
  padding: 0;
  grid-column-start: 1;
  grid-column-end: 2;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.movies-card__duration {
  margin: 0;
  padding: 0;
  grid-column-start: 1;
  grid-column-end: 2;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  color: #a0a0a0;
}

.movies-card__button {
  margin: 0;
  padding: 0;
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: 3;
  align-self: center;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  background-color: #f9f9f9;
  border: none;
  border-radius: 36px;
  cursor: pointer;
  box-sizing: border-box;
}

.movies-card__button_type_movies {
  background-image: url(../../images/movies-card__button.svg);
}

.movies-card__button_type_saved-movies {
  background-image: url(../../images/movies-card__button_type_delete.svg);
}

.movies-card__button:hover {
  opacity: 0.6;
}

.movies-card__button_active {
  background-image: url(../../images/movies-card__button_active.svg);
}

.movies-card__image {
  width: 100%;
  height: 203px;
  object-fit: cover;
  cursor: pointer;
}

@media (max-width: 768px) {
  .movies-card {
    max-width: 339px;
    height: 258px;
  }

  .movies-card__info {
    column-gap: 31px;
  }

  .movies-card__image {
    height: 190px;
  }
}

@media (max-width: 580px) {
  .movies-card__info {
    column-gap: 10px;
  }
}

@media (max-width: 425px) {
  .movies-card {
    max-width: 300px;
    height: 236px;
  }

  .movies-card__image {
    height: 168px;
  }
}
