.devider {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  min-height: 166px;
}

.devider_type_movies {
  min-height: 166px;
}

.devider_type_saved-movies {
  min-height: 140px;
}

.devider__button {
  margin: 0;
  margin-top: 80px;
  padding: 0;
  border: none;
  width: 320px;
  height: 36px;
  background-color: #f9f9f9;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
}

.devider__button_hide {
  visibility: hidden;
}

.devider__button:hover {
    opacity: 0.8;
}

@media (max-width: 320px) {
    .devider__button {
        margin-top: 79px;
        width: 240px;
      }
}
