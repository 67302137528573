.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 44px;
  background-color: #5c5c5c;
  width: 100%;
  box-sizing:border-box;
  padding-left: 70px;
  padding-right: 70px;
  min-height: 74px;
  margin: 0 auto;
  position: relative;
}

.header_logged-in {
  background-color: #ffffff;
}

.header__button-navigation {
  display: none;
  border: none;
  padding: 0;
  position: absolute;
  top: 15px;
  right: 30px;
  background-image: url(../../images/navigation__button.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 44px;
  height: 44px;
  background-color: #ffffff;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  z-index: 2;
}

.header__button-navigation:hover {
    opacity: 0.6;
  }

@media (max-width: 768px) {
  .header {
    max-width: 768px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .header__button-navigation_logged-in {
    display: block;
  }

  .header__button-navigation_opened {
    background-image: url(../../images/navigation__button_opened.svg);
    right: 23px;
    top: 22px;
    width: 31px;
    height: 31px;
    position: fixed;
  }
}

@media (max-width: 425px) {
    .header {
      max-width: 425px;
      padding-left: 14px;
      padding-right: 14px;
    }

    .header__button-navigation {
      width: 22px;
      height: 43px;
      right: 24px;
      background-position: center;
    }

    .header__button-navigation_opened {
      background-image: url(../../images/navigation__button_opened.svg);
      right: 18px;
      top: 13px;
      width: 22px;
      height: 32px;
    }
  }
