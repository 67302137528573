.footer {
  width: 89.0625%;
  margin: 0 auto;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.footer__header {
  width: 100%;
  grid-column-start: 1;
  grid-column-end: 4;
  justify-self: center;
  text-align: center;
  align-self: start;
  margin: 0;
  margin-top: 109px;
  padding: 0;
  padding-bottom: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #a0a0a0;
  border-bottom: 1px solid #a0a0a0;
}

.footer__copyright {
  justify-self: start;
  align-self: start;
  margin: 0;
  margin-top: 21px;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
}

.footer__links {
  grid-column-start: 2;
  grid-column-end: 4;
  justify-self: end;
  align-self: start;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px;
}

.footer__link {
  margin: 0;
  margin-top: 21px;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  text-decoration: none;
  color: black;
}

.footer__link:hover {
  opacity: 0.7;
}

@media (max-width: 768px) {
  .footer {
    width: 92.1875%;
  }
}

@media (max-width: 425px) {
  .footer {
    width: 88.75%;
    grid-template-columns: repeat(1, auto);
    grid-template-rows: auto;
    align-content: start;

  }

  .footer__header {
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    text-align: center;
    padding-bottom: 24px;
    font-size: 12px;
  }
  
  .footer__copyright {
    justify-self: center;
    margin-top: 0px;
    margin-top: 32px;
    font-style: normal;
    font-size: 12px;
    color:#A0A0A0;
  }
  
  .footer__links {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    justify-self: center;
    margin-top: 28px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
  
  .footer__link {
    font-size: 12px;
    margin-top: 0;
  }
}