.techs {
  width: 100%;
  min-height: 625px;
  margin: 0 auto;
  background-color: #f5f5f5;
}

.techs__title {
  width: 89.0625%;
  margin: 0 auto;
  padding: 0;
  padding-top: 104px;
  padding-bottom: 22px;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  letter-spacing: -1px;
  border-bottom: 1px solid #000000;
}

.techs__stack-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  list-style: none;
  gap: 10px;
  margin: 0;
  margin-top: 100px;
  padding: 0;
}

.techs__stack {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 60px;
  margin: 0;
  padding: 0;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  background-color: #e8e8e8;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .techs {
    min-height: 567px;
  }

  .techs__title {
    width: 86.979166%;
    padding-top: 93px;
  }

  .techs__stack-list {
    width: 86.979166%;
    margin: 0 auto;
    flex-wrap: wrap;
    margin-top: 83px;
  }

  .techs__stack {
    width: 84px;
    height: 57px;
    font-size: 12px;
  }
}

@media (max-width: 425px) {
  .techs {
    min-height: 651px;
  }

  .techs__title {
    width: 88.75%;
    padding-top: 72px;
    padding-bottom: 28px;
    font-size: 18px;
  }

  .techs__stack-list {
    margin: 0 auto;
    margin-top: 50px;
    height: 252px;
    max-width: 178px;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
